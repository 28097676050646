<template>
  <b-card class="login-body mb-5">
    <h3 class="text-center mt-4 mb-4">Aktivera ditt konto</h3>
    <form class="login-form" v-if="showForm">
      <b-alert
        :show="showWarning"
        variant="danger"
        dismissible
        @dismissed="showWarning = false"
        fade
        >{{$t('ACCOUNT.INVALID_EMAIL_PASSWORD')}}</b-alert
      >

      <b-form-group id="input-group-firstname" label="Förnamn" label-for="input-firstname">
        <b-form-input id="input-firstname" v-model="form.firstname" type="text"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-lastname" label="Efternamn" label-for="input-lastname">
        <b-form-input id="input-lastname" v-model="form.lastname" type="text"></b-form-input>
      </b-form-group>

      <b-form-group 
        id="input-group-email" 
        :label="$t('MEMBER.EMAIL')" 
        label-for="input-email">
        <b-form-input id="input-email" v-model="form.email" type="text"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-phone" label="Telefon" label-for="input-phone">
        <b-form-input id="input-phone" v-model="form.phone" type="text"></b-form-input>
      </b-form-group>

      <b-form-input 
        type="email" 
        v-model="form.email" 
        class="mb-8" 
        :placeholder="$t('MEMBER.EMAIL')" />
      <b-form-input type="password" v-model="form.password" class="mb-10" :placeholder="$t('MEMBER.PASSWORD')" />
      <div class="d-flex align-items-center justify-content-between mb-8">
        <router-link to="/forget" tag="a" class="line-link blue-link"
          >{{$t('MEMBER.FORGET_PASSWORD')}}</router-link
        >
        <b-button class="btn-login" variant="primary" @click="onSubmit">Aktivera konto</b-button>
      </div>
    </form>
    <div class="warning-message" v-else>
      <b-alert variant="danger" fade show>
        <div class="d-flex align-items-center justify-content-center p-4">
          <div>
            <fa-icon :icon="['far', 'question-circle']" size="3x"></fa-icon>
          </div>
          <div class="ml-4">
            Aktiveringslänken är antingen felaktig eller för gammal.
          </div>
        </div>
      </b-alert>
    </div>
  </b-card>
</template>

<style lang="scss" scoped>
.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
  .warning-message {
    padding: 30px 20px;
  }
}
</style>

<script>
import messageParent from '@/core/services/messageParent';
import { mapState } from 'vuex';
import { MEMBER_LOGIN, MEMBER_LOGOUT } from '@/core/services/store/member.auth.module';

import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  props: ['company_id','redirect'],
  name: 'activate',
  mixins: [ toasts ],
  data() {
    return {
      // Remove this dummy login info
      form: {
        company_id: '',
        client_id: '',
        public_id: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      },
      showWarning: false,
      showForm: false
    };
  },
  mounted() {
    var user_id = this.$route.params.user_id;
    var token = this.$route.params.token;


    this.getClientByToken(token);

    setTimeout(function(){
      messageParent(JSON.stringify({ height: document.body.scrollHeight }));
    }, 300);
  },
  methods: {
    getClientByToken(token) {
      axios
        .get(`/member/resettoken?token=${token}`)
        .then(res => {
          this.form = res.data;
          this.showForm = true;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta medlemsdata');
        });
    },
    async onSubmit() {

    },
    
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
